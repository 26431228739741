<template>
    <div class="title">
        <h1>YOU WOULDNT DOWNLOAD A ROBOT</h1>
    </div>
    <div class="about">
        <div class="entry">
            <p>do {<br>&ensp;&ensp; &ensp; fractionalRobots++<br>&ensp;&ensp;&ensp;}while(!heatDeath); <b class="blinkcursor">_</b></p>
        </div>
        <div class="module-wrapper">
            <div class="download-module">
    
                <div class="visuals">
                    <div class="image">
                        <iframe style="width:100%;height:100%;" src="/head.html" frameborder="0"></iframe>
                    </div>
                    <div class="desc">
                        <p class="module-title"><b>DROPBEAR.STP</b></p>
                        <p class="item-status">PROJECT PHASE <b>PROTOTYPE</b></p>
                        <p class="item-desc">The dropbear project is an open ended 3D printable biped aiming to improve access to applied AI and exploration of humanoid kinematics on a budget</p>
                    </div>
                </div>
                <div class="buttons">
                    <a target="download" href="/dropbear.zip">
                        <div class="download">
                            <h2>DOWNLOAD STEP FILE</h2>
                        </div>
                    </a>
    
                </div>
            </div>
            <div class="download-module">
    
                <div class="visuals">
                    <div class="image">
                        <iframe style="width:100%;height:100%;" src="/linear-actuator.html" frameborder="0"></iframe>
                    </div>
                    <div class="desc">
                        <p class="module-title"><b>LINEAR ACTUATOR</b></p>
                        <p class="item-status">PROJECT PHASE <b>PROTOTYPE</b></p>
                        <p class="item-desc">Consisting of just a few unique components, this project aims to lower the cost of high performance linear actuators. ballscrews should be matched with guide rail length</p>
                    </div>
                </div>
                <div class="buttons">
                    <a target="download" href="/54mm-stroke.stp">
                        <div class="download">
                            <h2>DOWNLOAD STEP FILE</h2>
                        </div>
                    </a>
    
                </div>
            </div>
            <div class="download-module">
    
                <div class="visuals">
                    <div class="image">
                        <iframe style="width:100%;height:100%;" src="/arm.html" frameborder="0"></iframe>
                    </div>
                    <div class="desc">
                        <p class="module-title"><b>ARM ASSEMBLY</b></p>
                        <p class="item-status">PROJECT PHASE <b>PROTOTYPE</b></p>
                        <p class="item-desc">This arm is still in development, in conjunction with evolve robotics</p>
                    </div>
                </div>
                <div class="buttons">
                    <a target="download" href="/arm.stp">
                        <div class="download">
                            <h2>DOWNLOAD STEP FILE</h2>
                        </div>
                    </a>
    
                </div>
            </div>
            <div class="download-module construction">
    
                <div class="visuals ">
                    <div class="image">
                        <iframe style="width:100%;height:100%;" src="/add.html" frameborder="0"></iframe>
                    </div>
                    <div class="desc">
                        <p class="module-title"><b>ADD YOUR OWN</b></p>
                        <p class="item-status">PROJECT PHASE <b>PROTOTYPE</b></p>
                        <p class="item-desc">This project will contain all of your files, instructions, materials, and multi-media guidance to successfully complete, or contribute to said project.</p>
                    </div>
                </div>
                <div class="buttons">
                    <a target="download" href="/leg.zip">
                        <div class="download ">
                            <h2>ADD PROJECT</h2>
                        </div>
                    </a>
    
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.about {
    position: absolute;
    z-index: 10;
    width: calc(100vw - 200px - 2rem);
    left: calc(200px + 2rem);
    top: 128px;
    padding-top: 1rem;
    height: auto;
    overflow: auto;
}

.construction {
    border: 2px dashed white !important;
    filter: saturate(0)contrast(1);
    overflow: hidden;
    transition: filter 0.2s ease;
    position: relative;
    &::after {
        transition: opacity 0.2s ease;
    }
    &:hover {
        filter: saturate(0)contrast(0.5);
        &::after {
            width: inherit;
            height: 100%;
            position: absolute;
            top: 0px;
            color: white;
            left: 0px;
            font-size: 4vw;
            content: 'COMING SOON';
            display: flex;
            flex-flow: column;
            text-align: center;
            opacity: 1;
            justify-content: center;
        }
    }
}

.module-wrapper {
    display: flex;
    flex-flow: wrap;
    gap: 2rem;
    padding-bottom: 1rem;
}

.buttons {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 1rem;
    width: auto;
}

.download {
    width: calc(35vw - 2px - 2rem);
    text-align: center;
    background: #ffd400;
    clip-path: polygon(0 0, calc(100% - 0.25rem) 0, 100% 0.25rem, 100% 100%, 0.25rem 100%, 0 calc(100% - 0.25rem));
    &:hover {
        background: #333;
        h2 {
            color: #ffd400;
            font-size: 2vw;
            line-height: 2;
            font-family: 'Rajdhani', sans-serif;
        }
    }
    h2 {
        color: #333;
        font-size: 2vw;
        line-height: 2;
        font-family: 'Rajdhani', sans-serif;
    }
}

.download-module {
    height: auto;
    width: 35vw;
    border: 2px solid #888;
    padding: 1rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    border-radius: 1rem;
    background: #111a;
    backdrop-filter: brightness(0.2)blur(20px);
}

.image {
    position: relative;
    height: 10vw;
    width: 10vw;
    cursor: pointer;
    iframe {
        pointer-events: none;
        user-select: none;
        transition: all 0.5s ease;
        box-shadow: inset 0px 0px 50px black;
        border: 1px solid #aaa2;
        border-radius: 100%;
        background: #333;
    }
    &::before {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        line-height: 1rem;
        content: "+";
        color: #ffd400;
        position: absolute;
        top: 0px;
        animation: swap1 .1s linear infinite;
        transition: all 0.2s ease;
    }
    &::after {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        line-height: 1rem;
        content: "+";
        color: #ffd400;
        position: absolute;
        bottom: 0px;
        animation: swap1 .1s linear infinite;
        animation-delay: 0.05s;
        transition: all 0.2s ease;
    }
    &:hover {
        &::before {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            line-height: 1rem;
            content: "+";
            color: white;
            position: absolute;
            top: 0px;
            animation: swap1 1s linear infinite;
            transition: all 0.2s ease;
        }
        &::after {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            line-height: 1rem;
            content: "+";
            color: white;
            position: absolute;
            bottom: 0px;
            animation: swap1 1s linear infinite;
            animation-delay: 0.5s;
            transition: all 0.2s ease;
        }
        iframe {
            border: 2px solid white;
            transform: scale(1.05);
        }
    }
    &:active {
        &::before {
            display: none;
            flex-flow: row;
            justify-content: space-between;
            content: "*";
            font-size: 2rem;
            line-height: 2rem;
            color: #ff2211;
            position: absolute;
            top: 0px;
            animation: swap1 1s linear infinite;
        }
        &::after {
            display: none;
            flex-flow: row;
            justify-content: space-between;
            content: "*";
            color: #ff2211;
            font-size: 2rem;
            line-height: 2rem;
            position: absolute;
            bottom: 0px;
        }
        iframe {
            border-radius: 0.5rem;
            filter: saturate(0)contrast(0.5)brightness(0.8);
            border: 2px solid red;
            background: transparent;
            transform: scale(1);
            box-shadow: inset 0px 0px 0px;
        }
    }
}

@keyframes swap1 {
    0% {
        right: 0px;
        left: unset;
    }
    50% {
        right: unset;
        left: 0px;
    }
    100% {
        right: 0px;
        left: unset;
    }
}

.visuals {
    display: flex;
    flex-flow: row;
    gap: 1rem;
}

.desc {
    max-width: 50%;
    .item-status {
        font-size: 1vw;
    }
    .module-title {
        color: #ffd400;
        font-size: 2vw;
    }
    .item-desc {
        border-top: 1px solid #ffd400;
        padding-top: 0.5rem;
        margin-top: 0.5rem;
        font-size: 0.8vw;
        width: calc(35vw - 4px - 3rem - 10vw);
    }
}

@keyframes blinkcursor {
    0% {
        opacity: 0;
    }
    49% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.blinkcursor {
    animation: blinkcursor 1s linear infinite;
}

.title {
    position: absolute;
    right: 50px;
    top: 50px;
    z-index: 20;
    width: calc(100vw - 200px - 2rem);
    h1 {
        text-shadow:5px 5px 0px black;
        font-size: 2vw;
        text-align: right;color:#ffd400;
    }
}

.entry {
    transform: rotate(5deg);
    font-weight: 800;
    color: #555;
    text-shadow: 2px 2px #262626;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 2rem;
    transform-origin: top left;
    position: relative;
    padding-right: 1rem;
    p {}
    &::after {
        content: "";
        width: 100%;
        height: 5rem;
        position: absolute;
        background-position: left;
        top: 1rem;
        left: 0rem;
        background-repeat: no-repeat;
        background-size: 10rem;
    }
}

@media (max-width: 1400px) {
    .visuals {
        display: flex;
        flex-flow: row;
        gap: 1rem;
    }
    .desc {
        max-width: unset;
        .module-title {
            color: #ffd400;
            font-size: 2rem;
        }
        .item-desc {
            border-top: 1px solid #ffd400;
            padding-top: 0.5rem;
            margin-top: 0.5rem;
            font-size: 0.8rem;
            width: calc(70vw - 4px - 3rem - 10vw);
        }
        .item-status {
            font-size: 1rem;
        }
    }
    .download {
        width: calc(70vw - 2px - 2rem);
        text-align: center;
        background: #ffd400;
        clip-path: polygon(0 0, calc(100% - 0.25rem) 0, 100% 0.25rem, 100% 100%, 0.25rem 100%, 0 calc(100% - 0.25rem));
        &:hover {
            background: #333;
            h2 {
                color: #ffd400;
                font-size: 2vw;
                line-height: 2;
                font-family: 'Rajdhani', sans-serif;
            }
        }
        h2 {
            color: #333;
            font-size: 2vw;
            line-height: 2;
            font-family: 'Rajdhani', sans-serif;
        }
    }
    .download-module {
        height: auto;
        width: 70vw;
        border: 2px solid #888;
        padding: 1rem;
        display: flex;
        flex-flow: column;
        gap: 1rem;
        border-radius: 1rem;
        background: #111a;
        backdrop-filter: brightness(0.2)blur(20px);
    }
}

@media (max-width: 1024px) {
    .title {
        position: absolute;
        right: 0px;
        top: 135px;
        z-index: 10;
        width: calc(100vw - 2rem);
        transform: rotate(5deg);
        h1{font-size:2.5vw;}
    }
    .about {
        position: absolute;
        z-index: 10;
        width: 100vw;
        left: 0px;
        top: 100px;
        padding-top: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .download {
        background: #ffd400;
        width: calc(90vw - 2px - 2rem);
        clip-path: polygon(0 0, calc(100% - 0.25rem) 0, 100% 0.25rem, 100% 100%, 0.25rem 100%, 0 calc(100% - 0.25rem));
        h2 {
            color: black;
            font-size: 2rem;
        }
        &:hover {
            h2 {
                color: black;
                font-size: 2rem;
            }
        }
    }
    .image {
        position: relative;
        height: calc(90vw - 4px - 2rem);
        width: calc(90vw - 4px - 2rem);
    }
    .visuals {
        flex-flow: column;
    }
    .desc {
        max-width: unset;
        .module-title {
            color: #ffd400;
            font-size: 2rem;
        }
        .item-desc {
            border-top: 1px solid #ffd400;
            padding-top: 0.5rem;
            margin-top: 0.5rem;
            font-size: 0.8rem;
            width: calc(90vw - 4px - 2rem);
        }
    }
    .download-module {
        height: auto;
        width: 90vw;
        margin: auto;
        border: 2px solid #888;
        padding: 1rem;
        display: flex;
        flex-flow: column;
        gap: 1rem;
        border-radius: 1rem;
        background: #111a;
        backdrop-filter: brightness(0.2)blur(20px);
    }
    .entry {
        transform: rotate(5deg);
        font-weight: 800;
        color: #555;
        text-shadow: 2px 2px #262626;
    }
}
</style>
